import * as React from 'react'
import styled from 'styled-components'
import { Link } from 'gatsby'
import Toggle from 'react-toggle'
import { Icon } from 'react-icons-kit'
import { moonO } from 'react-icons-kit/fa/moonO'
import { sunO } from 'react-icons-kit/fa/sunO'
import Navigation from './navigation'

interface HeaderProps {
  siteTitle: string
  theme: string
  setTheme: any
}

const HeaderWrapper = styled.header`
  position: relative;
  height: calc(var(--header-height) - 2rem);
  margin-bottom: 2rem;

  @media (min-width: 400px) {
    height: var(--header-height);
    margin-bottom: 4rem;
  }
`

const HeaderLine = styled.div`
  position: sticky;
  width: 100%;
  top: 0;
  height: 6px;
  background: rgb(64, 122, 164);
  background: -moz-linear-gradient(
    left,
    var(--primary-color) 0%,
    var(--secondary-color) 100%
  );
  background: -webkit-linear-gradient(
    left,
    var(--primary-color) 0%,
    var(--secondary-color) 100%
  );
  background: linear-gradient(
    to right,
    var(--primary-color) 0%,
    var(--secondary-color) 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#407aa4', endColorstr='#6657a6',GradientType=1 );
`

const HeaderContainer = styled.div`
  color: var(--text-color);
  margin: 0 auto;
  max-width: 75rem;
  padding: 0 1rem;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  h2 {
    margin: 0;
    font-size: 1.5rem;
    font-weight: normal;
    font-family: 'Snowburst One', 'Courier New', cursive;
  }

  @media (min-width: 400px) {
    h2 {
      font-size: 2rem;
    }
  }
`

const Header = ({ theme, setTheme }: HeaderProps) => (
  <HeaderWrapper>
    <HeaderLine />
    <Toggle
      defaultChecked={theme === 'dark'}
      onChange={(event: any) => {
        const { checked } = event.target
        setTheme(checked ? 'dark' : 'light')
      }}
      aria-label="Theme toggle (dark or light)"
      icons={{
        checked: <Icon icon={moonO} size={12} />,
        unchecked: <Icon icon={sunO} size={12} />,
      }}
    />
    <HeaderContainer>
      <h2>
        <Link
          to="/"
          style={{
            color: 'inherit',
            textDecoration: 'none',
          }}
        >
          JP Erasmus
        </Link>
      </h2>
      <Navigation />
    </HeaderContainer>
  </HeaderWrapper>
)

export default Header
