import * as React from 'react'
import styled from 'styled-components'
import { Link } from 'gatsby'
import { Icon } from 'react-icons-kit'
import { menu } from 'react-icons-kit/icomoon/menu'

const HeaderLinks = styled.ul<{ open?: boolean }>`
  list-style: none;
  margin: 0;
  transition: all 0.3s linear;
  display: block;

  @media (max-width: 679px) {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: ${({ open }): string => (open ? 'flex' : 'none')};
    flex-direction: column;
    align-items: center;
    justify-content: center;
    z-index: 3;
    background: var(--bg-color);

    > li {
      margin: 1rem 0rem;
    }
  }
`

const HeaderLink = styled.li`
  display: inline-block;
  margin: 0 0.5rem;
  line-height: 1;
  font-size: 1.1rem;

  > a {
    text-decoration: none;
    text-transform: uppercase;

    :hover,
    &.active {
      color: var(--primary-color);
    }
  }

  :last-child {
    margin-right: 0;
  }

  :first-child {
    margin-left: 0;
  }

  svg {
    transition: all 0.2s ease;
  }

  :hover svg {
    transform: scale(1.2);
  }
`

const HeaderNav = styled.nav`
  .menu-icon {
    display: inline-block;
  }

  @media (min-width: 680px) {
    .menu-icon {
      display: none !important;
    }
  }
`

const Navigation = (): JSX.Element => {
  const [open, setOpen] = React.useState(false)

  return (
    <HeaderNav>
      <Icon
        icon={menu}
        size={24}
        className="menu-icon"
        onClick={() => setOpen(!open)}
      />
      <HeaderLinks open={open} onClick={() => setOpen(!open)}>
        <HeaderLink>
          <Link activeClassName="active" to="/posts">
            Posts
          </Link>
        </HeaderLink>
        <HeaderLink>
          <a href="https://github.com/jperasmus">Github</a>
        </HeaderLink>
        <HeaderLink>
          <a href="https://twitter.com/jpunk11">Twitter</a>
        </HeaderLink>
      </HeaderLinks>
    </HeaderNav>
  )
}

export default Navigation
