import * as React from 'react'
import { Helmet, HelmetProvider } from 'react-helmet-async'
import { RouterProps } from '@reach/router'
import { graphql, useStaticQuery } from 'gatsby'
import styled from 'styled-components'

import Header from './header'
import Footer from './footer'
import './layout.css'
import { LayoutQueryData } from '../interfaces/LayoutQuery.interface'
import favicon16 from '../images/favicon-16x16.png'
import favicon32 from '../images/favicon-32x32.png'

export const themes = {
  light: {
    textColor: '#1b1b1d',
    backgroundColor: '#eff2f5',
    inlineCodeTextColor: '#342657',
    inlineCodeBackgroundColor: '#d1cef0',
  },
  dark: {
    textColor: '#eff2f5',
    backgroundColor: '#1b1b1d',
    inlineCodeTextColor: '#eff2f5',
    inlineCodeBackgroundColor: '#4B6CCF',
  },
}

const MainLayout = styled.main`
  max-width: var(--container-width);
  min-height: calc(100vh - var(--header-height) - var(--footer-height) - 10rem);
  margin: 1rem auto;
  padding: 0 1rem;
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 3rem;

  @media (min-width: 400px) {
    min-height: calc(
      100vh - var(--header-height) - var(--footer-height) - 8rem
    );
  }
`

type LayoutProps = { children: React.ReactNode } & RouterProps

const Layout = ({ children }: LayoutProps) => {
  const { site }: LayoutQueryData = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          # change siteMetaData in 'gatsby-config.js'
          title
          description
          keywords
          tagline
        }
      }
    }
  `)

  const [theme, setTheme]: [string, any] = React.useState(() => {
    return typeof window !== 'undefined'
      ? window.localStorage.getItem('jperasmus_theme') || 'light'
      : 'light'
  })

  React.useEffect(() => {
    const root = document.documentElement
    root.style.setProperty('--bg-color', themes[theme].backgroundColor)
    root.style.setProperty('--text-color', themes[theme].textColor)
    root.style.setProperty(
      '--inline-code-bg-color',
      themes[theme].inlineCodeBackgroundColor
    )
    root.style.setProperty(
      '--inline-code-text-color',
      themes[theme].inlineCodeTextColor
    )

    if (typeof window !== 'undefined') {
      window.localStorage.setItem('jperasmus_theme', theme)
    }
  }, [theme])

  const { title, description, keywords, tagline } = site.siteMetadata

  return (
    <HelmetProvider>
      <Helmet
        title={title}
        meta={[
          { name: 'description', content: description },
          { name: 'keywords', content: keywords || 'keywords' },
        ]}
        link={[
          {
            rel: 'icon',
            type: 'image/png',
            sizes: '16x16',
            href: `${favicon16}`,
          },
          {
            rel: 'icon',
            type: 'image/png',
            sizes: '32x32',
            href: `${favicon32}`,
          },
        ]}
      >
        <html lang="en" />
      </Helmet>
      <Header siteTitle={title} theme={theme} setTheme={setTheme} />
      <MainLayout>{children}</MainLayout>
      <Footer siteTagline={tagline} />
    </HelmetProvider>
  )
}

export default Layout
