import * as React from 'react'
import { Helmet } from 'react-helmet-async'
import { RouterProps } from '@reach/router'
import { PostTitle, PostDate, PostBody } from './styled'

import Layout from './layout'
import { PostQueryData } from '../interfaces/PostQuery.interface'

type PostLayoutProps = PostQueryData &
  RouterProps & { children: React.ReactNode }

const PostLayout = ({ data, ...props }: PostLayoutProps) => {
  if (data) {
    const { timeToRead, excerpt } = data.mdx
    const { title, date, canonical_url, tags, tweet } = data.mdx.frontmatter
    const { location, children } = props
    return (
      <Layout location={location}>
        <Helmet
          title={title}
          meta={[
            { name: 'description', content: excerpt || title },
            { name: 'keywords', content: tags || 'keywords' },
          ]}
        >
          <html lang="en" />
          {canonical_url ? <link rel="canonical" href={canonical_url} /> : null}
        </Helmet>
        <PostTitle>{title}</PostTitle>
        <PostDate>
          {date} · {timeToRead} min
        </PostDate>
        <PostBody>{children}</PostBody>
        {tweet ? (
          <a href={tweet} rel="noopener noreferrer">
            Discuss on Twitter
          </a>
        ) : null}
      </Layout>
    )
  }

  return <div>Nothing to show here</div>
}

export default PostLayout
