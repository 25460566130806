import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/postLayout.tsx";
import { graphql } from 'gatsby';
import ConcurrentConnectionsCaption from './concurrent-connections-caption.tsx';
export const domainShardingQuery = graphql`
  query DomainShardingQuery($pagePath: String!) {
    mdx(frontmatter: { path: { eq: $pagePath } }) {
      frontmatter {
        path
        title
        tags
        canonical_url
        tweet
        date(formatString: "MMMM DD, YYYY")
      }
      timeToRead
      excerpt
    }
  }
`;
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  domainShardingQuery,
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">




    <h3>{`TL;DR;`}</h3>
    <blockquote>
      <p parentName="blockquote">{`You probably don't need it. If possible, implement a service worker to cache front-end assets and serve up your app from a HTTP/2 supported server, but you probably don't need that either.`}</p>
    </blockquote>
    <p>{`Recently I've been tasked to implement domain sharding on one of our client's web apps following recommendations from a well-known technical consultancy company. Domain sharding is something I've implemented a few times before, but not recently, so I wanted to research the subject again. This is the conclusion I came to.`}</p>
    <h2>{`What is it?`}</h2>
    <p>{`Domain sharding is the official name for a technique used by web developers to load resources from multiple domains/subdomains in an attempt to overcome a browser's limit on the number of concurrent requests it can make, and therefore improving load performance. The premise of this is that browsers can only make a set limit of parallel requests per hostname, as suggested in the original HTTP/1.1 specification (last updated in 1992). At first glance, this sounds great — make all the things performant!`}</p>
    <h3>{`Number of concurrent requests per browser`}</h3>
    <p>{`Like all things web, finding a standard for the number of concurrent requests browsers support, is no straight forward task.`}</p>
    <p>{`For older browsers, like IE6/7, this limit was 2 requests per hostname, but with more modern browsers the limit ranges with an average of around 6. Another interesting limit is that browsers have a total limit of concurrent connections regardless of the number of different hostnames used. This can be a major reason against domain sharding, if you consider that some browsers only support 10–17 requests max in any case.`}</p>
    <p>{`These limits were decided on and implemented by browser vendors with much thought and experimentation to reduce the load on servers and find the optimum amount for most sites. Browser vendors cannot possibly be expected to implement a generic solution for all types of specific use cases. You as the developer would know best.`}</p>
    <p><img parentName="p" {...{
        "src": "https://cdn-images-1.medium.com/max/1600/1*a9x9kS_1CWiE38vXYjFO0A.png",
        "alt": "Concurrent connections per browser table"
      }}></img></p>
    <ConcurrentConnectionsCaption mdxType="ConcurrentConnectionsCaption" />
    <h2>{`When is it useful?`}</h2>
    <p>{`The main advantage of using domain sharding is to allow blocked assets to be downloaded faster than when they get queued and downloaded sequentially. Most CDN's as well as awesome services like `}<a parentName="p" {...{
        "href": "http://cloudinary.com/blog/reduce_site_load_time_with_multiple_cdn_sub_domains"
      }}>{`Cloudinary`}</a>{`, support multiple concurrent calls, so they are geared for domain sharding. It is generally most useful for image-heavy dynamic sites (think product catalogue on e-commerce website), where these images are frequently updated and a large number of them can exist on a single page. For a long time, it has been considered a best practice for these types of sites. Is this still relevant today? Let's continue…`}</p>
    <h3>{`I want to implement domain sharding; how many subdomains should I use?`}</h3>
    <p>{`In 2007, when domain sharding was still highly recommended, Yahoo did an `}<a parentName="p" {...{
        "href": "https://yuiblog.com/blog/2007/04/11/performance-research-part-4/"
      }}>{`experiment`}</a>{` and found that the optimum amount of subdomains were between 2 and 4, with anything more than 4 actually degrading the performance. One of the reasons for this is that each additional host requires new DNS lookups which could add 100–200ms+ to each call depending on where the DNS servers were located in relation to the user. If you are serving users globally, I would not use more than 2 subdomains.`}</p>
    <h3>{`SPDY & HTTP/2`}</h3>
    <p>{`In 2015, the `}<a parentName="p" {...{
        "href": "https://www.ietf.org/"
      }}>{`Internet Engineering Task Force (IETF)`}</a>{` released the second major version of the HTTP protocol, not surprisingly called HTTP/2. As a precursor to HTTP/2, Google wanted to scratch their own itch for a better protocol with reduced load latency and started developing the `}<a parentName="p" {...{
        "href": "https://developers.google.com/speed/spdy/"
      }}>{`SPDY`}</a>{` (pronounced Speedy) protocol. This set the stage for HTTP/2.`}</p>
    <p>{`It allows for sending multiple requests for data over a single TCP connection along with a number of other benefits, including fewer header data over the wire and keeping connections open for longer, making them a lot faster without the handshake overhead. It also requires that your site is served over HTTPS.`}</p>
    <p>{`HTTP/2 is not perfect, mostly because it needs to be backwards compatible with HTTP/1.1, but is a welcoming step in the right direction for the web. Read more it `}<a parentName="p" {...{
        "href": "https://www.upwork.com/hiring/development/the-http2-protocol-its-pros-cons-and-how-to-start-using-it/"
      }}>{`here`}</a>{` and `}<a parentName="p" {...{
        "href": "https://developers.google.com/web/fundamentals/performance/http2/"
      }}>{`here`}</a>{`.`}</p>
    <h3>{`Things to consider before implementing domain sharding`}</h3>
    <p>{`Domain sharding is very use-case specific and not a good solution for all sites. The technique is also a bit outdated now and had its glory days around 2009–2013 and before that when browsers had a much smaller limit of concurrent calls. If you are supporting only A-grade browsers for your site then concurrent requests are a much smaller problem.`}</p>
    <p>{`The most important thing to consider, in my opinion, is the browsers your site or web app needs to support. If you need to support older versions of IE (<IE10) I would not rule out scrapping domain sharding as a possible performance improvement.`}</p>
    <p>{`Domain sharding is not as useful for sites with mostly static assets that do not change often, because they will get cached in the browser's cache. The user will still feel the impact on the first load. You will get further by pushing down less Javascript, CSS and other assets to your users by making use of techniques like code-splitting, but that is a topic for another post.`}</p>
    <p>{`It's also important to ensure that domain sharding isn't done randomly and assets should consistently be loaded from the same sub-domain. This is to ensure that browser caching can be better utilized, which will always be much faster than any amount of parallel downloads. E.g.: if you load an image from `}<strong parentName="p">{`sub1.example.com`}</strong>{` then make sure that the image always gets loaded from `}<strong parentName="p">{`sub1.example.com`}</strong>{` and not sometimes from `}<strong parentName="p">{`sub2.example.com`}</strong>{`. Loading the image from different domains will actually worsen your page load performance.`}</p>
    <p>{`Investigate where in your web app or site you are actually running into assets which are being queued because of the browser's concurrent call limitation. It might not actually be a problem. Then, first try to reduce the number of assets before deciding on using domain sharding.`}</p>
    <p>{`If you are using a server supporting SPDY or HTTP/2 then domain sharding will most likely also hurt performance.`}</p>
    <h2>{`Final words & recommendation`}</h2>
    <p>{`My recommendation would be to implement a service worker and cache all front-end assets on the device's filesystem. This is by far the quickest method of getting assets loaded.`}</p>
    <blockquote>
      <p parentName="blockquote">{`The fastest network request is no network request.`}</p>
    </blockquote>
    <p>{`If it's not possible to implement a service worker because of browser support (hint: `}<a parentName="p" {...{
        "href": "https://jakearchibald.github.io/isserviceworkerready/"
      }}>{`browser support is not bad`}</a>{`) or you really want to use domain sharding, then my recommendation would be to add one additional subdomain (for front-end assets served from a CDN). This ensures that the assets are always loaded from the same host. If you have an API then that can be served from another domain.`}</p>
    <p>{`First try and optimize the number of requests being made so you don't have unnecessary calls blocking other more important calls. Additionally, look at implementing HTTP/2 support on your server.`}</p>
    <p>{`If you agree or disagree with anything I've written please let me know; I'm always interested in learning from others.`}</p>

    </MDXLayout>;
}
MDXContent.isMDXComponent = true;
      