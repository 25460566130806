import styled from 'styled-components'
import { Link } from 'gatsby'

export const ButtonLink = styled(Link)`
  color: var(--text-color);
  background: var(--bg-color);
  border: 1px solid var(--text-color);
  transition: all 0.25s ease-out;
  padding: 0.35rem 1rem;
  cursor: pointer;
  font-size: 0.8rem;
  font-weight: normal;
  line-height: 1;
  text-decoration: none;

  :hover {
    background: rgb(64, 122, 164);
    background: -moz-linear-gradient(
      left,
      var(--secondary-color) 0%,
      var(--primary-color) 100%
    );
    background: -webkit-linear-gradient(
      left,
      var(--secondary-color) 0%,
      var(--primary-color) 100%
    );
    background: linear-gradient(
      to right,
      var(--secondary-color) 0%,
      var(--primary-color) 100%
    );
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#407aa4', endColorstr='#6657a6',GradientType=1 );
    border-color: var(--bg-color);
  }
`

export const PostMeta = styled.em`
  display: block;
  font-size: 0.75rem;
  margin-bottom: 1rem;
`

export const PostTitle = styled.h1`
  text-align: center;
  text-decoration: none;
  background-image: linear-gradient(
    to right,
    var(--primary-color),
    var(--secondary-color)
  );
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  box-decoration-break: clone;
  -webkit-box-decoration-break: clone;
`

export const PostDate = styled.em`
  font-size: 0.75rem;
  text-align: center;
  display: block;
  margin-bottom: 1rem;
`

export const PostBody = styled.article`
  img {
    display: block;
    margin-left: auto;
    margin-right: auto;
    border: 10px solid var(--text-color);
  }

  img[src$='?fill-background'] {
    background: var(--text-color);
  }

  iframe {
    margin-left: auto;
    margin-right: auto;
    display: block;
  }

  a:hover {
    text-decoration: none;
    background-clip: text;
    background-image: linear-gradient(
      to right,
      var(--primary-color),
      var(--secondary-color)
    );
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    box-decoration-break: clone;
    -webkit-box-decoration-break: clone;
  }

  figcaption {
    text-align: center;
    margin-bottom: 1.5rem;
    font-size: 0.7rem;
  }
`
