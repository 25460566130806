import * as React from 'react'
import styled from 'styled-components'
import { Link } from 'gatsby'
import { Icon } from 'react-icons-kit'
import { home } from 'react-icons-kit/icomoon/home'
import { github } from 'react-icons-kit/icomoon/github'
import { twitter } from 'react-icons-kit/icomoon/twitter'
import HoneyCombPattern from '../images/honeycomb-pattern.png'

interface FooterProps {
  siteTagline: string
}

const FooterWrapper = styled.footer`
  height: calc(var(--footer-height) + 2rem);
  position: relative;
  margin-top: 2rem;
  background: url(${HoneyCombPattern}) repeat,
    linear-gradient(
      to right,
      var(--primary-color) 0%,
      var(--secondary-color) 100%
    );
  background-blend-mode: overlay;

  @media (min-width: 400px) {
    height: var(--footer-height);
    margin-top: 4rem;
  }
`

const FooterContainer = styled.div`
  margin: 0 auto;
  max-width: 75rem;
  min-height: 100%;
  padding: 0 1rem;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  justify-content: space-evenly;

  @media (min-width: 400px) {
    text-align: left;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
`

const Tagline = styled.span`
  margin: 0;
  font-family: Verdana, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto,
    Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
  font-weight: bold;
  text-rendering: optimizeLegibility;
  font-size: 0.78405rem;
  line-height: 1.1;
`

const FooterLinks = styled.ul`
  list-style: none;
  margin: 0;
`

const FooterLink = styled.li`
  display: inline-block;
  margin: 0 0.5rem;

  :last-child {
    margin-right: 0;
  }

  :first-child {
    margin-left: 0;
  }

  svg {
    transition: all 0.2s ease;
  }

  :hover svg {
    transform: scale(1.2);
  }
`

const Footer = ({ siteTagline }: FooterProps) => (
  <FooterWrapper>
    <FooterContainer>
      <Tagline>{siteTagline}</Tagline>
      <FooterLinks>
        <FooterLink>
          <Link to="/" aria-label="Home">
            <Icon icon={home} size={24} />
          </Link>
        </FooterLink>
        <FooterLink>
          <a
            href="https://github.com/jperasmus"
            aria-label="View GitHub profile"
          >
            <Icon icon={github} size={24} />
          </a>
        </FooterLink>
        <FooterLink>
          <a
            href="https://twitter.com/jpunk11"
            aria-label="View Twitter profile"
          >
            <Icon icon={twitter} size={24} />
          </a>
        </FooterLink>
      </FooterLinks>
    </FooterContainer>
  </FooterWrapper>
)

export default Footer
